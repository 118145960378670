import React, { useState } from "react";
import { ProgressBar, Row, Col, Card, Image, Spinner } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { Stars, Star, StarFill, ArrowRepeat } from "react-bootstrap-icons";
import { ImageProvider } from "./ImageProvider";
import AchievementJson from "../../../shared_ressources/achievements.json";

// Define level names
const levelNames = AchievementJson.LEVEL_NAMES;

// Define achievements levels
const achievementLevels = AchievementJson.ACHIEVEMENT_LEVEL;

const achievementCategory = AchievementJson.ACHVIEVEMENT_CATEGORY;

const achievement_explanations = AchievementJson.ACHIEVEMENT_EXPLANATIONS;

const AchievementProgressComponent = ({
	userAchievements,
	userAchievementCount,
	userAchievementCountPercentage,
	getAndSetUserStatistics,
}) => {
	const [showRefreshSpinner, setShowRefreshSpinner] = useState(false);

	const progressBars = Object.keys(levelNames).map((category, index) => {
		if (category === "n_donations") {
			return;
		}
		const userAchievement = userAchievements[category];
		const levels = achievementLevels[category];
		const names = levelNames[category];

		let currentLevelIndex = levels.findIndex(
			(level) => level > userAchievement,
		);
		if (currentLevelIndex === -1) {
			currentLevelIndex = levels.length;
		}
		const currentLevelName = names[currentLevelIndex - 1] || "None";
		const nextLevelName =
			names[currentLevelIndex] || names[achievementLevels[category].length - 1];

		const progress =
			(userAchievements[category] /
				achievementLevels[category][currentLevelIndex]) *
			100;

		return (
			<React.Fragment key={category}>
				<Row className="mt-3">
					<Col
						className="d-flex justify-content-center align-items-center"
						xs={2}
					>
						<Image
							height="50rem"
							src={ImageProvider.achievements[`${category}.svg`]}
						/>
					</Col>
					<Col xs={3}>
						<>
							<div
								className="fw-bold"
								data-tooltip-id={`explanation-tooltip-${category}`}
								data-tooltip-variant="dark"
							>
								{achievementCategory[category]}
							</div>
							<Tooltip
								id={`explanation-tooltip-${category}`}
								style={{ maxWidth: "20rem" }}
							>
								{achievement_explanations[category]}
							</Tooltip>
						</>
					</Col>
					<Col xs={3}>
						<>
							<div
								className="d-flex justify-content-center"
								style={{ fontStyle: "italic" }}
								data-tooltip-id={`current-level-tooltip-${category}`}
								data-tooltip-variant="dark"
							>
								{currentLevelName}
								{Array(currentLevelIndex).fill(
									<StarFill size={10} key={currentLevelIndex} />,
								)}
							</div>
							<Tooltip id={`current-level-tooltip-${category}`}>
								<b>
									{userAchievementCount[currentLevelName] || 0} (
									{userAchievementCountPercentage[currentLevelName] || 0}%){" "}
								</b>{" "}
								users have this achievement.
							</Tooltip>
						</>
					</Col>
					<Col xs={3}>
						<div
							className="d-flex justify-content-center"
							style={{ fontStyle: "italic" }}
							data-tooltip-id={`next-tooltip-${category}`}
							data-tooltip-variant="dark"
						>
							{nextLevelName}
							{Array(currentLevelIndex + 1).fill(<StarFill size={10} />)}
						</div>
						<Tooltip id={`next-tooltip-${category}`}>
							<b>
								{userAchievementCount[nextLevelName]} (
								{userAchievementCountPercentage[nextLevelName]}%){" "}
							</b>{" "}
							users have this achievement.
						</Tooltip>
					</Col>
				</Row>
				<Row>
					<div
						data-tooltip-id={`progress-tooltip-${category}`}
						data-tooltip-variant="dark"
					>
						<ProgressBar
							now={progress}
							variant="warning"
							label={`${progress.toFixed(2)}%`}
						/>
					</div>
					<Tooltip
						id={`progress-tooltip-${category}`}
						style={{ maxWidth: "8rem" }}
						className="text-center"
					>
						<b>
							{userAchievements[category]} /{" "}
							{achievementLevels[category][currentLevelIndex]}
						</b>
					</Tooltip>
				</Row>
				{/* {index < Object.keys(levelNames).length - 2 && <hr />} */}
			</React.Fragment>
		);
	});

	return (
		<Card>
			<Card.Body>
				<div className="d-flex justify-content-between">
					<div className="display-6 mb-3">
						Achievements <Stars />
					</div>
					<div>
						{showRefreshSpinner ? (
							<>
								<Spinner />
							</>
						) : (
							<ArrowRepeat
								onClick={() => {
									getAndSetUserStatistics(setShowRefreshSpinner);
								}}
								style={{ cursor: "pointer" }}
								size={35}
							/>
						)}
					</div>
				</div>
				<Row>
					<Col xs={2} />
					<Col xs={3} />
					<Col
						xs={3}
						className="d-flex justify-content-center fw-bold align-items-center"
					>
						{"Current"}
					</Col>
					<Col />
					<Col
						xs={3}
						className="d-flex justify-content-start fw-bold align-items-center"
					>
						{"Next"}
					</Col>
					<hr className="mt-1" />
				</Row>
				<div>{progressBars}</div>
			</Card.Body>
		</Card>
	);
};

export default AchievementProgressComponent;
