import React from "react";
import { Accordion, ListGroup, Alert, Container } from "react-bootstrap";

/**
 * BookEditorAdviceDisplay Component
 *
 * @param {Object} props - Component props
 * @param {Object} props.adviceData - JSON object matching BookEditorAdvice model
 * @param {Array}  props.adviceData.spelling_mistakes - Array of SpellingMistake objects
 * @param {Array}  props.adviceData.grammatical_errors - Array of GrammaticalError objects
 * @param {Array}  props.adviceData.expression_erros - Array of ExpressionError objects
 * @param {Array}  props.adviceData.praise - Array of praise strings
 */
function BookEditorAdviceDisplay({ adviceData }) {
	const {
		spelling_mistakes = [],
		grammatical_errors = [],
		expression_erros = [],
		praise = [],
	} = adviceData;

	const renderMistakesList = (mistakesArray, title) => {
		if (!mistakesArray.length) {
			return <ListGroup.Item>No {title.toLowerCase()} found.</ListGroup.Item>;
		}
		return mistakesArray.map((item, idx) => (
			<ListGroup.Item key={idx}>
				<strong>Mistake:</strong>
				<br /> {item.mistake} <br />
				<strong>Correction:</strong>
				<br /> {item.correction}
			</ListGroup.Item>
		));
	};

	const renderPraiseList = (praiseArray) => {
		if (!praiseArray.length) {
			return <ListGroup.Item>No praise messages found.</ListGroup.Item>;
		}
		return praiseArray.map((msg, idx) => (
			<ListGroup.Item key={idx}>{msg}</ListGroup.Item>
		));
	};

	return (
		<Container className="mt-4">
			<Alert variant="warning" className="text-center">
				<strong>Disclaimer:</strong> The following content was generated by an
				AI. While we strive for accuracy, errors may be present. Please review
				carefully.
			</Alert>

			<Accordion defaultActiveKey="0">
				{/* Spelling Mistakes */}
				<Accordion.Item eventKey="0">
					<Accordion.Header>Spelling Mistakes</Accordion.Header>
					<Accordion.Body>
						<ListGroup>
							{renderMistakesList(spelling_mistakes, "Spelling Mistakes")}
						</ListGroup>
					</Accordion.Body>
				</Accordion.Item>

				{/* Grammatical Errors */}
				<Accordion.Item eventKey="1">
					<Accordion.Header>Grammatical Errors</Accordion.Header>
					<Accordion.Body>
						<ListGroup>
							{renderMistakesList(grammatical_errors, "Grammatical Errors")}
						</ListGroup>
					</Accordion.Body>
				</Accordion.Item>

				{/* Expression Errors */}
				<Accordion.Item eventKey="2">
					<Accordion.Header>Expression Errors</Accordion.Header>
					<Accordion.Body>
						<ListGroup>
							{renderMistakesList(expression_erros, "Expression Errors")}
						</ListGroup>
					</Accordion.Body>
				</Accordion.Item>

				{/* Praise */}
				<Accordion.Item eventKey="3">
					<Accordion.Header>Praise</Accordion.Header>
					<Accordion.Body>
						<ListGroup>{renderPraiseList(praise)}</ListGroup>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</Container>
	);
}

export default BookEditorAdviceDisplay;
