import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import LoadingIndicatorButton from "./LoadingIndicatorButton";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { urls } from "./urls";
import { postJson } from "./utils";
import { Tooltip } from "react-tooltip";
import icon from "../../images/icon.svg";

const EditorAdviceButton = ({
	setCornuvotaiAlertMessage,
	setCornuvotaiAlertState,
	setCornuvotaiFeedback,
	slateContent,
	slateWordCount,
}) => {
	const [aiLoading, setAiLoading] = useState(false);
	const [aiModalShow, setAiModalShow] = useState(false);
	const [cornuvotaiCredits, setCornuvotaiCredits] = useState(0);

	useEffect(() => {
		fetch(urls.baseUrl + urls.getUserGenerationCreditsForFeedback)
			.then((r) => r.json())
			.then((data) => setCornuvotaiCredits(data.credits));
	}, []);

	const handleConfirm = async (event) => {
		if (slateWordCount < 100 || slateWordCount > 3000) return;
		setAiLoading(true);
		trackPromise(
			postJson(urls.generateWritingFeedback, { content: slateContent }).then(
				(data) => {
					setAiLoading(false);
					setAiModalShow(false);
					if (data.error) {
						setCornuvotaiAlertMessage(data.error);
						setCornuvotaiAlertState(true);
						return;
					}
					const feedback = data.feedback;
					setCornuvotaiFeedback(feedback);
					setCornuvotaiCredits((prev) => prev - 1);
				},
			),
			"ai",
		);
	};
	return (
		<>
			<Button
				variant="info"
				type="button"
				size="lg"
				data-tooltip-id="ai-tooltip"
				data-tooltip-content="Get feedback from CornuvotAI"
				data-tooltip-variant="info"
				onClick={() => setAiModalShow(true)}
			>
				<img
					src={icon}
					alt="icon"
					className="mx-1"
					style={{ width: 30, height: 30 }}
				/>
				CornuvotAI
			</Button>
			<Tooltip id="ai-tooltip" />
			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={aiModalShow}
				onHide={() => setAiModalShow(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Cautious now traveller!
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Credits: <b>{cornuvotaiCredits}</b>. This feature is based on a
					limited credit system. If you don't have any credits left come back
					tomorrow.
					<br />
					<br />
					CornuvotAI Feedback is an experimental feature. It will try to you
					give you some feedback on grammar, expression and style. It is only
					enabled for texts longer than 100 words and shorter than 5000
					characters. (Your word count: {slateWordCount}).
					<br />
					<br />
					This feature will send your plain text but no other information to a
					third-party LLM provider. Your input will not be used for training
					data. Anyway please make sure there are no PII in your text. Consult
					our privacy policy for more information.{" "}
					<Link to="/privacypolicy">Privacy policy</Link>.
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="info"
						onClick={handleConfirm}
						disabled={aiLoading || slateWordCount < 100}
					>
						<LoadingIndicatorButton area="ai" />
						Confirm
					</Button>
					<Button variant="secondary" onClick={() => setAiModalShow(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default EditorAdviceButton;
