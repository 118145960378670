import { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoadingIndicatorButton from "./LoadingIndicatorButton";

function VerticallyCenteredModal(props) {
	const [textareaContent, setTextareaContent] = useState("");
	const [loading, setLoading] = useState(false);

	const handleConfirm = () => {
		setLoading(true);
		if (props.hastextinput) {
			if (textareaContent.length > 200) {
				setLoading(false);
				return;
			}
			props.onConfirm(textareaContent);
			setTextareaContent("");
		} else {
			props.onConfirm();
			setLoading(false);
		}
	};

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div style={{ overflowY: "auto", maxHeight: "75vh" }}>{props.body}</div>
				{props.hastextinput && (
					<textarea
						className="mt-2"
						value={textareaContent}
						onChange={(e) => setTextareaContent(e.target.value)}
						style={{ width: "100%", padding: "10px" }}
					/>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="danger" onClick={handleConfirm} disabled={loading}>
					<LoadingIndicatorButton />
					Confirm
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export { VerticallyCenteredModal };
